<template>
	<v-layout wrap>
		<v-container>
			<v-btn
				icon
				@click="$router.go(-1)"
				class="mr-2">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
		</v-container>

		<!-- <forbidden-alert
			:value="forbidden">
			<p>Щоб отримати доступ, зв'яжись зі своїм викладачем 🤫</p>
		</forbidden-alert> -->

		<v-flex xs12 md8>
			<vimeo-player
				v-if="lesson && lesson.video_id"
				:videoId="lesson.video_id"
				:cuePoint="cuePoint"
				style="max-height: 600px"
				@has-seen="markLesson"/>
		</v-flex>
		<v-flex xs12 md4>
			<v-container v-if="lesson">
				<h1 class="text-h5 pb-5 font-weight-black primary--text">{{ lesson.name }}</h1>
				<!-- <v-layout no-gutters>
					<v-flex xs4
						class="flex-column text-center">
						<small class="overline">тривалість</small>
						<div class="display-1 font-weight-black font-italic">{{ lesson.duration || 0 }}</div>
						<small>хв</small>
					</v-flex>
					<v-divider vertical/>
					<v-flex
						v-if="lesson.file_id"
						xs8
						class="flex-column text-center">
						<small class="overline">матеріали</small>
						<div>
							<file-downloader
								:id="lesson.file_id"
								:name="lesson.name"
								type="pdf">
								<v-icon>mdi-file-download</v-icon>
							</file-downloader>
						</div>
						<small>завантажити</small>
					</v-flex>
					<v-col
						v-else
						xs4
						class="d-flex justify-center align-center text-center">
						<small class="overline primary--text">немає матеріалів</small>
					</v-col>
					<v-divider vertical/>
					<v-flex xs4
						class="flex-column text-center"
						v-if="lesson.result">
						<small class="overline">тести</small>
						<div>
							<v-btn
								:to="{
									name: 'test',
									params: {
										testid: lesson.test_id,
									}
								}"
								text
								:loading="loading"
								color="success"
								class="display-1 font-weight-black font-italic success--text">
								{{ lesson.result.result }}%
							</v-btn>
						</div>
						<small>результат</small>
					</v-flex>
					<v-flex xs4
						class="flex-column text-center"
						v-else-if="lesson.test_id">
						<small class="overline">тести</small>
						<div>
							<v-btn
								:to="{
									name: 'test',
									params: {
										testid: lesson.test_id,
									}
								}"
								text
								:loading="loading"
								color="success">
								<v-icon>mdi-file-check</v-icon>
							</v-btn>
						</div>
						<small>пройти</small>
					</v-flex>
					<v-col
						v-else
						xs4
						class="d-flex justify-center align-center text-center">
						<small class="overline primary--text">немає тестів</small>
					</v-col>
				</v-layout>
				<v-divider/> -->

				<v-expansion-panels>
					<v-expansion-panel
						v-if="lesson.files[0]">
						<v-expansion-panel-header>
							Файли
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-list>
								<v-list-item
									v-for="file in lesson.files"
									:key="file.id">
									<v-list-item-icon>
										<v-btn
											:href="file.original_url"
											color="success"
											target="_blank"
											download
											text
											@click="download">
											<v-icon>mdi-file-download</v-icon>
										</v-btn>

										<v-list-item-content>
											<v-list-item-title
												v-text="file.file_name">
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item-icon>
								</v-list-item>
							</v-list>
						</v-expansion-panel-content>
					</v-expansion-panel>

					<v-expansion-panel
						v-if="lesson.tests[0]">
						<v-expansion-panel-header>
							Тести
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-list>
								<v-list-item
									v-for="test in lesson.tests"
									:key="test.id"
									:to="{
										name: 'test',
										params: {
											testid: test.id,
										},
									}">

									<v-list-item-content>
										<v-list-item-title
											v-text="test.name">
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-container>
		</v-flex>
		<v-flex xs12>
			<v-container>
				<article
					v-if="lesson && lesson.text"
					v-html="lesson.text">
				</article>
			</v-container>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { apiFactory } from '../api/apiFactory'
import VimeoPlayer from '../components/VimeoPlayer'
// import ForbiddenAlert from '../components/ForbiddenAlert'

const completedLessonsApi = apiFactory.get('completedLessons')

export default {
	props: {
		lessonid: {
			type: Number,
			required: true,
		},
	},
	components: {
		VimeoPlayer,
		// ForbiddenAlert,
	},
	data: () => ({
		loading: false,
		forbidden: false,
		cuePoint: 0.9,
	}),
	computed: {
		...mapGetters('lessons', [
			'lesson',
		]),
	},
	methods: {
		...mapActions('lessons', [
			'loadLesson',
			'unloadLesson',
		]),
		async markLesson() {
			try {
				await completedLessonsApi.create(this.lessonid)
			} catch (error) {
				console.error(error)
			}
		},
	},
	async mounted() {
		try {
			await this.loadLesson(this.lessonid)
			this.loading = false
		} catch (error) {
			if (error.response.status === 403) this.forbidden = true
			this.forbidden = true
		}
	},
	beforeDestroy() {
		this.unloadLesson()
	},
}
</script>
