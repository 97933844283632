<template>
	<div ref="player" id="vimeo-player"></div>
</template>

<script>
import Player from '@vimeo/player'
 
export default {
	props: {
		videoId: {
			type: String,
			required: true,
		},
		cuePoint: {
			type: Number,
			required: false,
		},
		responsive: {
			type: Boolean,
			default: true,
		},
	},
	data: () => ({
		player: null,
	}),
	methods: {
		async init() {
			this.player = new Player(this.$refs.player, {
				id: this.videoId,
				responsive: this.responsive,
			})

			const duration = await this.player.getDuration()
			this.player.addCuePoint((duration * this.cuePoint).toFixed(0), {
				event: 'has-seen',
			})

			this.player.on('cuepoint', ({ data }) => {
				this.$emit(`${data.event}`)
			})
		},
	},
	mounted() {
		this.init()
	},
}
</script>

<style>
#vimeo-player iframe {
	max-height: 600px;
}
</style>